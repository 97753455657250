import React, {Component} from 'react';
import fire from '../config/Fire';
import Login from '../components/core/login/Login';
import Home from '../components/core/home/Home';
import MuiThemeProvider from '@material-ui/styles/ThemeProvider';
import Theme from '../components/material/themes/themes';
import './scss/App.scss';

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
        }
    }

    componentDidMount() {
        this.authListener();
    }

    authListener() {
        fire.auth().onAuthStateChanged((user) => {
            console.log(user);
            if (user) {
                this.setState({user});
                localStorage.setItem('user', user.uid);
            }

            else {
                this.setState({user: null});
                localStorage.removeItem('user');
            }
        })
    }

    render() {
  return (
    <div className="App">
        <MuiThemeProvider theme={Theme}>
        {this.state.user ? (<Home />) : (<Login/>) }
        </MuiThemeProvider>
    </div>
  );
}
}

export default App;

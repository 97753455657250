import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/textField';

const styles = theme => ({
    TextField: {
            width: "300px",
            marginTop: "8px",
            marginBottom: "24px",
    },

    cssLabel: {
        '&$cssFocused': {
            color: "#696969",
        }
    },
    cssFocused: {},
});

function StyledTextField(props) {
    const { classes } = props;

    return (
        <div>
             <TextField InputLabelProps={{
      classes: {
        root: classes.cssLabel,
        focused: classes.cssFocused
      }
    }}  label={props.label} value={props.passedValue} onChange={props.event} type={props.myType} name={props.label} className={classes.TextField}/>
        </div>
    );
}

export default withStyles(styles)(StyledTextField);
import firebase from 'firebase';

        const firebaseConfig = {
            apiKey: "AIzaSyDN42Vy2eREk4OuOiWvvOCZcKpjtHIp3qs",
            authDomain: "capable-mind-250822.firebaseapp.com",
            databaseURL: "https://capable-mind-250822.firebaseio.com",
            projectId: "capable-mind-250822",
            storageBucket: "",
            messagingSenderId: "1035320496486",
            appId: "1:1035320496486:web:7af76d530f2ce018"
    };
        // Initialize Firebase
       const fire = firebase.initializeApp(firebaseConfig);
        export default fire;
import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import './appBar.scss'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

const NavBar = () => {
    return (
        <div>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="caption" color="inherit">
                        Welcome to SkillScripts!
                    </Typography>
                </Toolbar>
            </AppBar>
        </div>
    )};

export default NavBar;
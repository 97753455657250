import React, { Component } from 'react';
import fire from '../../../config/Fire';
import NavBar from '../appBar/AppBar';
// import Button from '@material-ui/core/Button';
import StyledBtn from '../../material/buttons/defaultBtn/Buttons';
import UncontrolledLottie from '../../animations/sample/sample'

class Home extends Component {
    constructor(props) {
        super(props);
        Home.logout = Home.logout.bind(this);
    }


     static logout() {
        fire.auth().signOut();
    }


    render() {
        return (
        <div className="Home">
            <NavBar/>
            <StyledBtn name="hi"/>
            <UncontrolledLottie/>

        {/*<Button color="primary" onClick={Home.logout}> Logout </Button>*/}
        </div>
    );
    }
}

export default Home;

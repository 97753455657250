import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
    button: {
        margin: "24px, 0, 0, 0",
        borderRadius: "20px",
        textTransform: "none"
    },
    input: {
        display: 'none',
    },
    contained: {
        boxShadow: "0px 3px 7px rgba(106, 130, 251, 0.6)",
        "&:hover": {
            boxShadow: "0px 5px 20px rgba(106, 130, 251, 0.35)"
        }
    },
        leftIcon: {
        marginRight: theme.spacing(1),
      },
      rightIcon: {
        marginLeft: theme.spacing(1),
      },
      iconSmall: {
        fontSize: 20,
      }
    }));

function StyledBtn(props) {
    const classes = useStyles();

    return (
        <div>
            <Button color="secondary" variant={props.variant} contained= {classes.contained}
            // {{
            //     classes: {
            //     root: classes.contained
            // }
            // }} 
            onClick={props.event} classes={{
                root: classes.button,
                contained: classes.contained
                }}
                >
                {props.name}
            </Button>
        </div>
    );
}

export default StyledBtn;
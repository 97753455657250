import { createMuiTheme } from '@material-ui/core/styles';

const Theme = createMuiTheme({
    palette: {
        //Primary Color Pallete
        primary: {
            "main": "#FAD13C",
            "light": "#ffffa8",
            "dark": "#FAD13C"
        },
        // p900:"#fa6500",
        // p800: "#fa8600",
        // p700: "#fa9800",
        // p600: "#f9ab00",
        // p500: "#f8b900",
        // p400: "#f9c301",

        // Secondary Color Pallete
        secondary: {
            "main": "#6A82FB"
        }
        // //Contrast
        // white: "#ffffff",
        // w100: "#fafafa",
        // w200: "#f5f5f5",
        // w300: "#f0f0f0",
        // w400: "#dedede",
        // w500: "#c2c2c2",
        // w600: "#979797",
        // w700: "#818181",
        // w800: "#606060",
        // darkGrey: "#3c3c3c"
    },
    status: {
        danger: 'orange',
    },
    //Global usages
    overrides: {
        MuiButton: { // Name of the component ⚛️ / style sheet
            text: { // Name of the rule
                color: 'black', // Some CSS
            },
        },
        // MuiInputLabel: {
        //     root: {
        //       "&$focused": {
        //         color: "#696969"
        //       },
        //     },
        // }
    }
});

export default Theme;
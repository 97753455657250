import React, { Component } from 'react';
import './Login.scss'
import fire from '../../../config/Fire';
import StyledTextField from '../../material/textFields/TextFields';
import StyledBtn from '../../material/buttons/defaultBtn/Buttons';
import AddIcon from '@material-ui/icons/Add';
import { ReactComponent as Logo} from '../../../assets/logos/Logo.svg';


class Login extends Component {
    constructor(props) {
        super(props);
        this.login = this.login.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            email: '',
            password: ''
        };
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    login(e) {
        e.preventDefault();
        fire.auth().signInWithEmailAndPassword(this.state.email, this.state.password).then((u)=>{
        }).catch((error) => {
            console.log(error);
        });
    }

    
    
    render() {
    
        return (
            <div className="Login">
                <form className="myForm">
                    <Logo/>
                    <div className="formGroup">
                       <StyledTextField label="email" passedValue={this.state.email} event={this.handleChange} myType="email"/>
                        <StyledTextField label="password" passedValue={this.state.password} event={this.handleChange} myType="password"/>
                    </div>

                    <div className="buttonGroup">
                    <StyledBtn variant="contained" name="Login" type="submit" event={this.login} className="btn btn-primary">Login</StyledBtn>
                    </div>
                </form>
            </div>
        );
    }
}
export default Login;